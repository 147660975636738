import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import React from 'react';

import { AdminProvider } from './src/context/admin';
import './src/css/custom.scss';
import theme from './src/libs/theme';

export const wrapPageElement = ({ element }) => {
	return (
		<MantineProvider theme={theme}>
			<ModalsProvider>
				<AdminProvider>
					<Notifications position="top-right" />
					{element}
				</AdminProvider>
			</ModalsProvider>
		</MantineProvider>
	);
};
