import { useLocalStorage } from '@mantine/hooks';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import constants from '../libs/constants';

const default_settings = {
	aside_size: 'small',
	language_id: 1,
	partner_id: null,
};

const default_values = {
	aSideClose: () => {},
	aSideOpen: () => {},
	aSideState: '',
	constants: {},
	language: '',
	languages: [],
	setSetting: () => {},
	setUser: () => {},
	settings: {},
	user: {},
};

export const AdminContext = createContext(default_values);

export const AdminProvider = ({ children }) => {
	const [aSideState, setASideState] = useState();
	const [settings, setSettings] = useLocalStorage({
		defaultValue: default_settings,
		key: 'zample_settings',
	});

	const aSideClose = useCallback(() => {
		setASideState(null);
	}, [setASideState]);

	const aSideOpen = useCallback(
		({ component, data }) => {
			setASideState({ component, data });
		},
		[setASideState],
	);

	const setSetting = useCallback(
		(key, value) => {
			if (!(key in default_settings)) return;

			setSettings((current) => {
				let new_current = { ...current };
				new_current[key] = value;
				return new_current;
			});
		},
		[setSettings],
	);

	const language = useMemo(() => {
		return constants.languages.find((item) => item.id == settings?.language_id);
	}, [settings]);

	const [user, setUser] = useState();

	const values = useMemo(() => {
		return {
			aSideClose,
			aSideOpen,
			aSideState,
			constants,
			language,
			setSetting,
			setUser,
			settings,
			user,
		};
	}, [
		aSideClose,
		aSideOpen,
		aSideState,
		language,
		user,
		setSetting,
		settings,
		setUser,
	]);

	return (
		<AdminContext.Provider value={values}>{children}</AdminContext.Provider>
	);
};

AdminProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
